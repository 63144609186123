@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

* {
  box-sizing: border-box;
  text-decoration: none;
}

body {
  font-family: 'Lato', sans-serif;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease;
  min-height: 100vh;
}

body.show-nav {
  transform: translateX(200px);
}

ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  height: 4rem;
  padding: 2rem;
  border-bottom: solid 2px #404274;
  list-style: none;
}

nav ul li a {
  color: white;
  font-size: 1rem;
}
P {
  font-size: 1.1rem;
  line-height: 2rem;
}
#portfolio li a:hover {
  color: red;
}
